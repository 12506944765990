import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/hanne/src/HS/strautmann.dev/src/templates/markdown-page.js";
import Button from "../components/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Jobs`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#werkstudent-softwareentwickler-cloud-services"
        }}>{`Werkstudent: Software-Entwickler Cloud Services`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#studentenprojekt-teilautonomes-fahren"
        }}>{`Studentenprojekt: Teilautonomes Fahren`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#studentenprojekt-augmented-reality-demonstrator"
        }}>{`Studentenprojekt: Augmented Reality Demonstrator`}</a></li>
    </ul>
    <h2>{`Werkstudent: Software-Entwickler Cloud Services`}</h2>
    <h3>{`Inhalt`}</h3>
    <p>{`Für laufende IoT Projekte suche ich Unterstützung.
Du wirst Backend Services im AWS Serverless Umfeld (weiter)entwickeln, das Monitoring dafür einrichten, und Frontends für Service Techniker und Endkunden programmieren.
Die Projekte laufen relativ lange und entsprechend ändern sich die akuten Themen.`}</p>
    <h3>{`Anforderungen`}</h3>
    <p>{`Diese Fähigkeiten werden im Projekt gebraucht. Du musst sie nicht alle schon zu Beginn beherrschen.`}</p>
    <ul>
      <li parentName="ul">{`Programmiersprache: z.B. python, TypeScript, JSX, React`}</li>
      <li parentName="ul">{`Serverless Computing, bevorzugt AWS Lambda mit Serverless Framework`}</li>
      <li parentName="ul">{`Datenanalyse, Reports, Anomalieerkennung`}</li>
      <li parentName="ul">{`Erste NoSQL Erfahrungen`}</li>
      <li parentName="ul">{`git`}</li>
      <li parentName="ul">{`Sprache: Deutsch oder Englisch`}</li>
    </ul>
    <div className="bg-gray-100 mt-5 p-3 text-center">
  <div>
  Klingt interessant?
  </div>
  <Button link="/kontakt/" text="Dann nimm Kontakt zu mir auf" primary mdxType="Button" />
    </div>
    <h2>{`Studentenprojekt: Teilautonomes Fahren`}</h2>
    <h3>{`Inhalt`}</h3>
    <p>{`Ausgang für dieses Projekt ist ein kleines Roboterauto, auf dem Batterien, eine Kamera und ein Raspberry Pi installiert sind.
In den Kamerabildern soll ein Marker (z.B. Aruco) erkannt werden.
Das Auto soll dann auf diesen zufahren, aber auch einen bestimmten Abstand dazu nicht unterschreiten.
Der Videostream der Kamera soll auf dem Raspberry verarbeitet werden, und die Motoren des Autos werden auch vom Raspberry angesteuert.
Teil des Projekts ist auch die intrinsische Kalibrierung der Kamera, sowie die Referenzierung der Kamera zum Auto.`}</p>
    <h3>{`Anforderungen`}</h3>
    <p>{`Diese Fähigkeiten werden im Projekt gebraucht. Du musst sie nicht alle schon zu Beginn beherrschen.`}</p>
    <ul>
      <li parentName="ul">{`Bildverarbeitung / Computer Vision / OpenCV`}</li>
      <li parentName="ul">{`Programmiersprache Deiner Wahl, z.B. python oder dotnet oder C++`}</li>
      <li parentName="ul">{`git`}</li>
      <li parentName="ul">{`Raspberry Pi / Linux`}</li>
      <li parentName="ul">{`Sprache: Deutsch oder Englisch`}</li>
    </ul>
    <div className="bg-gray-100 mt-5 p-3 text-center">
  <div>
  Klingt interessant?
  </div>
  <Button link="/kontakt/" text="Dann nimm Kontakt zu mir auf" primary mdxType="Button" />
    </div>
    <h2>{`Studentenprojekt: Augmented Reality Demonstrator`}</h2>
    <h3>{`Inhalt`}</h3>
    <p>{`Ausgang für dieses Projekt ist eine RGB Kamera, z.B. ein Handy oder eine Webcam.
In den Kamerabildern soll ein Marker (z.B. Aruco) erkannt werden.
An der Position des Markers soll dann ein anderes Objekt eingezeichnet werden, z.B. ein 2D-Bild oder ein skaliertes 3D-Modell.
Der modifizierte Videostream soll angezeigt werden.
Bevorzugt wird das Projekt als Handy-App umgesetzt.
Teil des Projekts ist auch die intrinsische Kalibrierung der Kamera.`}</p>
    <h3>{`Anforderungen`}</h3>
    <p>{`Diese Fähigkeiten werden im Projekt gebraucht. Du musst sie nicht alle schon zu Beginn beherrschen.`}</p>
    <ul>
      <li parentName="ul">{`Bildverarbeitung / Computer Vision / OpenCV`}</li>
      <li parentName="ul">{`Programmiersprache Deiner Wahl, z.B. flutter / Dart, Java, Kotlin, python, dotnet oder C++`}</li>
      <li parentName="ul">{`git`}</li>
      <li parentName="ul">{`Sprache: Deutsch oder Englisch`}</li>
    </ul>
    <div className="bg-gray-100 mt-5 p-3 text-center">
  <div>
  Klingt interessant?
  </div>
  <Button link="/kontakt/" text="Dann nimm Kontakt zu mir auf" primary mdxType="Button" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      